import { LANG, USER } from '../../../IWWJsCode';
import CartButton from './_CartButton';
import ChapterBox from './_ChapterBox';

export default function SectionBox({ section, key }) {
  let { isUserSection } = USER();
  let { financialPrice } = LANG();


  const isOpen = isUserSection(section);

  return (
    <>
      <div key={key} className="accordion-item">
        <div className="accordion-header">
          <span className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + section.id} aria-expanded="true" aria-controls="collapseOne">
            <i className="fas fa-chevron-down"></i>
            <div className="d-flex justify-content-between w-100">
              <h2 className='mx-2'>
                {isOpen ? <><i className='fas fa-lock-open text-dark mx-1'></i></> :
                  !section.isFree ? <><i className='fas fa-lock text-dark mx-1'></i></> : ''}
                  
                {section.name}</h2>
              <div>
                {!section.isFree && !isOpen ? (
                  <>
                    {section.price ? <strong className='d-block'>{financialPrice(section.price)} {section.currency}</strong> : ''}

                    <CartButton paymentType={'Payment'} price={section.price} type='Section' id={section.id} />
                  </>
                ) : ''}
              </div>
            </div>
          </span>
        </div>
        {/* data-bs-parent="#accordionExample" */}
        <div id={"collapseOne" + section.id} className={"accordion-collapse collapse show"} >
          <div className="accordion-body">
            <div className="col-sm-12">
              <div className="card">
                {section.chapters && section.chapters.length && section.chapters.map((chapter, chapkey) => <ChapterBox chapter={chapter} key={chapkey} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";

const CartContext = createContext({});

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  let [cartItems, setCartItems] = useState(
    localStorage.getItem("IWWCart")
      ? JSON.parse(localStorage.getItem("IWWCart"))
      : []
  );

  const addItem = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      alert("item aleady added");
    } else {
      setCartItems([...cartItems, item]);
    }
  };

  const toggleItemInner = (itemId, item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === itemId);

    if (!isItemInCart) {
      alert("item not found");
    } else {
      const items = cartItems.filter((cartItem) => cartItem.id !== itemId);
      if (!isItemInCart.inner)
        isItemInCart.inner = [];

      let innerItem = isItemInCart.inner.find((cartItem) => cartItem.id === item.id);
      if (innerItem)
        isItemInCart.inner = isItemInCart.inner.filter((cartItem) => cartItem.id != item.id)
      else
        isItemInCart.inner = [...isItemInCart.inner, item]

      setCartItems([...items, isItemInCart]);
    }
  };

  const removeItem = (id) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== id));
  };

  const UpdateItemPayment = (id, payment) => {
    const items = cartItems;

    const objIndex = items.findIndex(obj => obj.id === id);

    items[objIndex].paymentType = payment;
    items[objIndex].inner = [];
    setCartItems(items);

    UpdateLocalStorage();
  };

  const emptyCart = () => {
    setCartItems([]);
  };

  useEffect(() => {
    UpdateLocalStorage();
  }, [cartItems]);

  function UpdateLocalStorage() {
    localStorage.setItem("IWWCart", JSON.stringify(cartItems));
  }

  return (
    <>
      <CartContext.Provider
        value={{ cartItems, addItem, removeItem, emptyCart, UpdateItemPayment, toggleItemInner }}
      >
        {children}
      </CartContext.Provider>
    </>
  );
}

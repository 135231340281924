import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { stagger, useAnimate, usePresence } from 'framer-motion';

import { GET_IMAGE_URL, USER } from '../../../IWWJsCode';

export default function CourseBox({ course }) {
  const { isUserCourse } = USER();
  const { t } = useTranslation();

  // const [isPresent, safeToRemove] = usePresence();
  // const [scope, animate] = useAnimate();

  // useEffect(() => {
  //   if (isPresent) {
  //     const enterAnimate = async () => {
  //       await animate(scope.current, { opacity: [0, 1] }, { duration: 2, delay: stagger(1.5) });
  //     };
  //     enterAnimate()
  //   } else {
  //     const exitAnimate = async () => {
  //       await animate(scope.current, { opacity: [1, 0] }, { duration: 2, delay: stagger(1.5) });
  //       safeToRemove()
  //     };
  //     exitAnimate()
  //   }
  // }, [course]);
  const isOpen = isUserCourse(course?.id);

  return (
    course ?
      <>
        <div data-user-course={isOpen} key={course.id} className="card card-course border-0 shadow">
          <div className="card-cover">
            <img src={GET_IMAGE_URL(course.image)} className="course-image" alt={course.name} />
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between py-3">
              <small>
                <i className="fas fa-book mx-1"></i>
                <span>{course.chaptersCount} {t('Chapters')}</span>
                {/* <sub>{course.sectionCount}</sub> */}
              </small>
              <small data-active={course.active}>
                <i className="fas fa-calendar-alt mx-1"></i>
                <span>{course.endDate}</span>
              </small>
            </div>
            <div className="course-name py-3">
              <h5>
                {!course.isFree ?
                  <>
                    {isOpen ?
                      <><i className='fas fa-lock-open text-dark mx-1'></i></> : <><i className='fas fa-lock text-dark mx-1'></i></>
                    }
                  </> : ('')}

                {course.name}
              </h5>
              <small>{course.university}</small>
              <p>{course.instructor?.name}</p>
              {course.summary ? (<p>{course.summary}</p>) : ('')}
            </div>
            <div className="card-footer text-center border-top">
              <Link to={'/Course/' + course.id} className="text-decoration-none btn btn-warning text-white">
                Explore
              </Link>
            </div>
          </div>
        </div>

        {/* <div className='courses-item position-relative'>
        <Link ref={scope} key={course.id} to={'/Course/' + course.id} className='text-decoration-none'>
          <div className='overflow-hidden'>
            <div className='courses-item-img'>
              <img src={GET_IMAGE_URL(course.image)} alt={course.name} className='w-100' />
            </div>
          </div>
          <div className='courses-data position-relative'>
            {course.durationInHours && course.durationInHours > 0 ? (
              <h6 className='text-muted'>
                <i className='fas fa-clock mr-1'></i>
                {course.durationInHours} hour
              </h6>
            ) : (
              ''
            )}
            <p className={'position-absolute top-0 end-0 ' + (course.active ? 'text-success' : 'text-danger')}>{course.endDate}</p>
            <h6>{course.name}</h6>
            <p>{course.summary}</p>
            <hr />
            {course.priceBefore && course.priceBefore > 0 ? (
              <h5 className='price-free'>
                {course.priceBefore} {currency}
              </h5>
            ) : (
              ''
            )}
          </div>
        </Link>
        <div className='position-absolute bottom-0 start-0 p-1'>
          {isUserCourse(course.id) ? <span className='badge text-bg-secondary'>Your's</span> : !course.isFree && course.price ? <CartButton paymentType={'Payment'} price={course.price} type='Course' id={course.id} /> : <span className='btn-warning free'>Paid</span>}
        </div>
      </div> */}
      </>
      : ('')
  );
}

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { APIURL } from '../IWWJsCode';

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}
export function UserProvider({ children }) {
  
  let [user, setUser] = useState();
  let [loadingUser, setLoadingUser] = useState();

  async function getUser() {
    setLoadingUser(true);
    await axios
      .get(APIURL('auth/user'))
      .then((resp) => {
        if (resp && resp.data && resp.data.success && resp.data.user) {
          setUser(resp.data.user);
        } else {
          setUser(null)
        }
      })
      .catch(function (error) {
        console.error('UserContext.getUser:ERROR', error);
      });
    setLoadingUser(false);
  }

  const isUserChapter = (chapter) => {
    let isSubscription = user?.subscriptions != undefined && user.subscriptions.length;

    if (user == null || !isSubscription) return false;

    let isChapter = isSubscription && user.subscriptions.some((i) => i.id == chapter.id && i.type == 'Chapter');
    let isSection = isSubscription && user.subscriptions.some((i) => i.id == chapter.sectionId && i.type == 'Section');
    let isCourse = isSubscription && user.subscriptions.some((i) => i.id == chapter.courseId && i.type == 'Course');

    return isChapter || isSection || isCourse;
  };

  const isUserSection = (section) => {
    let isSubscription = user?.subscriptions != undefined && user.subscriptions.length;

    if (user == null || !isSubscription) return false;

    let isSection = isSubscription && user.subscriptions.some((i) => i.id == section.id && i.type == 'Section');
    let isCourse = isSubscription && user.subscriptions.some((i) => i.id == section.courseId && i.type == 'Course');

    return isSection || isCourse;
  };

  function isUserCourse(courseId) {
    let isSubscription = user?.subscriptions != undefined && user.subscriptions.length;
    if (user == null || !isSubscription) return false;


    let isCourse = isSubscription && user.subscriptions.some((i) => i.id == courseId && i.type == 'Course');

    return isCourse;
  }

  useEffect(() => {
    getUser();
  }, []);

  return <UserContext.Provider value={{ user, loadingUser, setUser, getUser, isUserChapter, isUserSection, isUserCourse }}>{children}</UserContext.Provider>;
}

import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CartButton from '../Materials/Partials/_CartButton';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function MyProjects() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = USER();

  let { lang, currency } = LANG();
  let [projects, setProjects] = useState();

  useEffect(() => {
    if (!user) {
      navigate('/login?rurl=/my-projects');
    }

    if (user) {
      // setLoading(true);
      fetch(APIURL(lang + `/userProjects/myProjects`))
        .then((res) => res.json())
        .then((json) => {
          setProjects(json.projects);
          // setLoading(false);
        });
    }
    SetTitle('My Projects');
    window.scrollTo(0, 0);
  }, [lang, user]);

  return user && user.Active ? (
    <>
      <section>
        <div class='container'>
          <div class='row justify-content-center'>
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/profile'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {projects ? (
              <>
                <div className='col-12'>
                  <div class='row mt-2'>
                    <div class='col-md-12'>
                      <h3>Projects</h3>
                      <ul className='list-group'>
                        {projects.map((proj, indx) => (
                          <>
                            <li key={indx} className={'list-group-item text-start'}>
                              {proj.submissions ? (
                                <span className='float-end'>
                                  <Link className='btn btn-dark' to={'/project/' + proj.id}>
                                    <i className='fas fa-file-import mx-1'></i> Submission
                                  </Link>
                                </span>
                              ) : (
                                ''
                              )}
                              <h4 className='me-1 mb-0'>
                                {proj.name}
                                {proj.price ? (
                                  <strong className='float-end'>
                                    {proj.price} {currency}
                                  </strong>
                                ) : (
                                  ''
                                )}
                              </h4>
                              <small>
                                {proj.mode} • {proj.status}
                              </small>
                              <a className='btn float-end btn-light mt-3' target='_blank' href={`/StudentManage/Project/${proj.id}`}>
                                <i class='fas fa-cogs text-dark mx-1'></i> Manage
                              </a>
                              <a className='btn float-end btn-light mt-3' target='_blank' href={`https://wa.me/96565659468?text=*Project Name:* ${proj.name} *Instructor:* ${proj.instructor} \n\r *Status:* ${proj.status} \n\r *Question:* _your question?_`}>
                                <i class='fab fa-whatsapp text-success mx-1'></i> Ask admin
                              </a>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No Projects')}</p>
              </div>
            )}
            <div className='col-12'>
              <a className='btn btn-light btn-lg mt-3' target='_blank' href={`https://wa.me/96565659468?text=*Project Name:* _NewProjectName_ \n\r *Summary:* _Type some info about project_ \n\r *Students:* _[${user.Phone}, -Add more friends numbers-]_`}>
                <i class='fab fa-whatsapp text-success mx-1'></i> Chat with admin to create your projects
              </a>
              <a className='btn btn-light btn-lg mt-3' target='_blank' href='/StudentManage'>
                Add project manually
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    ''
  );
}

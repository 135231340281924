/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useTranslation } from 'react-i18next';

import UniversityBox from '../Materials/Partials/_UniversityBox';
import { Loader, SetTitle } from '../../IWWJsCode';
import { useApiData } from '../../Contexts/ApiDataContext';
import { useEffect } from 'react';

export default function Universities() {
  const { t } = useTranslation();
  let { universities } = useApiData();
  let { loading } = Loader();

useEffect(()=>{
  SetTitle('Universities');
});

  return (
    <>
      <section class="bg-sec-course my-5 p-5">
        <div class="container">
          <div class="row justify-content-center g-3">

            <div class="col-sm-12">
              <div class="">
                <h6 class="rounded-pill bg-pill d-inline px-3 py-1 text-white">
                  {t('Universities List')}
                </h6>
                <div class="about-text my-4">
                  <h3>
                    {t('Universities')}
                    <span>
                      {t('Courses')}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            {universities && universities.length > 0 ? (
              universities.map((univ, idx) => (
                <div key={idx} class="col-lg-3 col-md-4 col-sm-6">
                  <UniversityBox university={univ} />
                </div>
              ))
            ) : (
              <>
                {!loading && universities && universities.length === 0 ? (
                  <h3 className='text-muted text-center text-secondary'>
                    <i className='far fa-university mx-2'></i>
                    No Universities
                  </h3>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

import { createContext, useContext, useState } from 'react';

const LoaderContext = createContext();

export function useLoader() {
  return useContext(LoaderContext);
}

export function LoaderProvider({ children }) {
  let [loading, setLoading] = useState(false);

  return <LoaderContext.Provider value={{ loading, setLoading }}>{children}</LoaderContext.Provider>;
}

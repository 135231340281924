import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CourseBox from '../Materials/Partials/_CourseBox';
import { Loader, SetTitle, USER } from '../../IWWJsCode';

export default function MyCourses() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, getUser } = USER();
  let { loading, setLoading } = Loader();

  useEffect(() => {
    setLoading(true);
    CheckUser();
    setLoading(false);
    window.scrollTo(0, 0);
  }, [user]);

  useEffect(() => {
    SetTitle("My Courses");
  });

  function CheckUser() {
    if (!user)
      navigate('/login');
  }

  return user && user.Active ? (
    <>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/profile'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {user.subscriptions && user.subscriptions.length ? (
              <>
                <div class="col-md-12">
                  <h3><i className='fas fa-compact-disc mx-2'></i> {t('My subscriptions')}</h3>
                  <ul className="list-group">
                    {user.subscriptions.map((item, indx) => (
                      <>
                        <li key={indx} className={"list-group-item text-start"}>
                          <span className='badge mx-1 float-start text-bg-warning'>{item.type}</span>
                          <h4 className='me-1'>{item.name}</h4>
                          {item.endDate ? <Link to={'/my-installments/' + item.type + '/' + item.id} className='btn btn-outline-secondary mx-2'>Installments {item.endDate}</Link> : ''}
                          <Link className='text-decoration-none btn btn-warning float-end' to={(item.courseId) ? '/Course/' + item.courseId : (item.type != 'Section') ? '/' + item.type + '/' + item.id : ''}>Explore</Link>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No subscribed courses')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  ) : ('');
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom';
import $ from "jquery";

import { useApiData } from '../../Contexts/ApiDataContext';
import { USER, CART, LANG, GET_IMAGE_URL } from '../../IWWJsCode';

import logo_virtical from '../../images/virtical-logo.png';
import logo from '../../images/logo.png';
import userAvatar from '../../images/user.jpg';
import arFlag from '../../images/ar.png';
import enFlag from '../../images/en.png';

export default function Navbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { universities } = useApiData();
  const { cartItems } = CART();
  const { user, loadingUser } = USER();
  const { lang, setLang } = LANG();

  let [navCollapsed, setNavCollapsed] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams({});
  let [searchText, SetSearchText] = useState(searchParams.get('txt'));

  useEffect(() => {
    setNavCollapsed(true);
  }, [pathname]);

  useEffect(() => {
    const sectionTop = document.querySelector("#convert")?.offsetTop;
    if (sectionTop) {
      window.addEventListener("scroll", () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > sectionTop) {
          const navbar = document.querySelector(".navbar");
          navbar.classList.add("nav-bg");
          navbar.classList.add("shadow");
        } else {
          const navbar = document.querySelector(".navbar");
          navbar.classList.remove("nav-bg");
          navbar.classList.remove("shadow");
        }
      });
    }
  }, [])

  function toggleNavCollapsed() {
    setNavCollapsed(!navCollapsed);
  };


  function onChangeLang(_lang) {
    setLang(_lang);
  };

  function searchInputChange(txt) {
    SetSearchText(txt);
  }

  function submitSearch() {
    if (window.location.pathname != "/courses") {
      navigate({
        pathname: "courses",
        search: createSearchParams({ s: searchText }).toString()
      });
    } else {
      setSearchParams((prev) => { prev.set('s', searchText); return prev; }, { replace: true });
    }

    $('#searchModal').find('[data-bs-dismiss]').click();
  }
  $('#searchModal').on('shown.bs.modal', function () {
    $('#searchModal input#input-search').focus();
  })

  return (
    <>
      <nav className="navbar navbar-expand-lg border-end ">
        <div className="container">
          <NavLink to="home" className="text-decoration-none">
            <img src={logo_virtical} className="logo-virtical d-none d-md-block" alt="smart vision logo" />
            <img src={logo} className="logo d-md-none" alt="smart vision logo" />
          </NavLink>

          <div className="ms-auto px-3 color-search">
            <i className="fas fa-search" aria-describedby="basic-addon1" data-bs-toggle="modal" data-bs-target="#searchModal"></i>
          </div>

          <button className="navbar-toggler border-0" onClick={() => { toggleNavCollapsed(); }} type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded={navCollapsed ? "false" : "true"} aria-label="Toggle navigation">
            {(navCollapsed) ? <> <span className="fas fa-bars fs-1"></span></> : <><span className="fas fa-times fs-1"></span></>}
          </button>

          <div className={"collapse navbar-collapse" + (navCollapsed ? "" : " show")} id="main_nav">
            <ul className="navbar-nav m-auto mt-0 mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className='nav-link link-hover' aria-current='page' to='courses'>
                  {t('Courses')}
                </NavLink>
              </li>
              {universities && universities.length ? (
                <>
                  <li className="nav-item dropdown has-megamenu">
                    <a className="nav-link link-hover" href='#' data-bs-toggle="dropdown">
                      {t('Universities')}
                      <i className="fas fa-caret-down mx-1"></i>
                    </a>
                    <div className="dropdown-menu megamenu" role="menu">
                      <div className="col-megamenu">
                        <div className="row">
                          {universities.map((el) => (
                            <div key={el.id} className="col-md-3 col-12">
                              <NavLink exact className="text-decoration-none dropdown-item" to={`courses?u=${el.id}`}>
                                <i className="fas fa-university mx-1"></i>
                                {el.name}
                              </NavLink>
                            </div>
                          ))}
                          <div className="col-12">
                            <NavLink className="text-decoration-none btn-lg dropdown-item active" to={'universities'}>
                              <i className="fas fa-university mx-1"></i>
                              {t('All Universities')}
                            </NavLink>
                          </div>
                        </div>

                      </div>
                    </div>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>

            <button className="btn-search" id="search-input" aria-describedby="basic-addon1" data-bs-toggle="modal" data-bs-target="#searchModal">
              {(!searchText || searchText === '' ? "Search.." : searchText)}
            </button>

            <ul className="navbar-nav media ms-auto mb-2 mb-lg-0  align-items-center">
              <li className="nav-item dropdown">
                <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src={(lang == 'en') ? enFlag : arFlag} className="flag" alt="current flag" />
                </a>
                <ul className="dropdown-menu ">
                  <li>
                    <span onClick={() => { onChangeLang('ar'); }} className="dropdown-item disabled">
                      <img src={arFlag} className="flag" alt="egypt flag" />
                      عربي
                    </span>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <span onClick={() => { onChangeLang('en'); }} className="dropdown-item">
                      <img src={enFlag} className="flag" alt="english flag" />
                      English
                    </span>
                  </li>
                </ul>
              </li>



            </ul>

          </div>
          <ul className="navbar-nav d-flex flex-row gap-2">


            <li className="nav-item d-flex align-items-center">
              {loadingUser ? (
                <span className='btn rounded-pill bttn'>
                  <i className='fas fa-circle-notch text-light fa-spin'></i>
                </span>
              ) : (
                <>
                  {user != null ? (
                    <NavLink className='nav-link text-decoration-none user-p' aria-current='page' to='profile'>
                      <span>{user.FName}</span>
                      <img src={user.image ? GET_IMAGE_URL(user.image) : userAvatar} className="user" alt="user image" />
                    </NavLink>
                  ) : (
                    <NavLink className='nav-link text-decoration-none user-p' aria-current='page' to='login'>
                      <span>{t('Login')}</span>
                      <img src={userAvatar} className="user" alt="user image" />
                    </NavLink>
                  )}
                </>
              )}
            </li>


            <li className="nav-item mt-2">
              <NavLink className='nav-link perant  fs-5' aria-current='page' to='Checkout'>
                <i className='fas fa-shopping-cart cart'></i>
                <span className='count'>{cartItems.length}</span>
              </NavLink>
            </li>

          </ul>
        </div>
      </nav>

      <div className="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-end">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <input id="input-search" className="form-control"
                name='search'
                type="search"
                onKeyDown={(e) => {
                  if (e.charCode === 13 || e.keyCode === 13)
                    submitSearch();
                }}
                onChange={(e) => { searchInputChange(e.target.value); }} placeholder={t('Search') + ".."} aria-label="Username" value={searchText} />
              <div className="d-flex justify-content-center">
                <button onClick={() => { submitSearch(); }} className="btn btn-warning mt-3">
                  {t('Search')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
// import overlay from '../../images/overlay-bottom.png';
import axios from 'axios';
import { GET_IMAGE_URL, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function () {
  let [termsData, settermsData] = useState();
  let { setLoading } = Loader();
  let { lang } = LANG();

  useEffect(() => {
    gettermsData();
    SetTitle("Terms and Conditions");
  }, [lang]);

  async function gettermsData() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/terms')).catch((err) => {
      // Handle errors
      console.error(err);
    });
    settermsData(data);
    setLoading(false);
  }
  
  return termsData && termsData.length ? (
    <>
      {/* <div className='shape-menu'>
        <img src={overlay} alt='' className='w-100' />
      </div> */}
      <div className='new-about about-page'>
        <div className='container'>
          <div className='row'>
            {termsData.map((el, i) => (
              <div key={i} className='about-item'>
                <div className='row serv'>
                  <div className={el.image || el.image2 ? 'col-12 col-md-6' : 'col-12 col-md-12'}>
                    <h3 className='head-section'>{el.title}</h3>
                    <div className='about-data' dangerouslySetInnerHTML={{ __html: el.htmlDescription }}></div>
                  </div>
                  {el.image || el.image2 ? (
                    <div className='col-12 col-md-6'>
                      <div className='about-img'>
                        <img src={GET_IMAGE_URL(el.image, el.image2)} alt='' srcSet='' className='w-100' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function MyOrders() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, getUser } = USER();
  let { setLoading } = Loader();
  let { lang, financialPrice } = LANG();
  let [orders, setOrders] = useState([]);

  useEffect(() => {
    setLoading(true);
    CheckUser();
    setLoading(false);
    window.scrollTo(0, 0);
  }, [user]);

  useEffect(() => {
    getUser();
    SetTitle("My Orders");
  }, []);

  function CheckUser() {
    if (!user)
      navigate('/login');

    if (user?.orders)
      setOrders(user.orders);
  }

  async function GetOrders() {
    setLoading(true);

    fetch(APIURL(lang + '/payments/Orders'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.orders) setOrders(json.orders);
        setLoading(false);
      });
  }

  return user && user.Active ? (
    <>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/profile'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {orders && orders.length ? (
              <>
                <div className='col-12'>
                  <h1 className='h3'><i className='fas fa-file-invoice-dollar mx-2'></i>{t('My Orders')}</h1>
                  <a className='btn float-end btn-outline-light'
                    onClick={(e) => { GetOrders(); }}>
                    {t('Check Recent Payments')}
                  </a>
                </div>
                {orders.map((order, sindex) => (
                  <>
                    <div key={sindex} class="col-md-8 col-sm-12">
                      <div class="card card-info border-0 shadow my-3">
                        <div class="card-body">
                          <div class="order d-md-flex align-items-center justify-content-between d-sm-inline-block">
                            <h6>{order.date}</h6>
                            <p>{financialPrice(order.amount)} {order.currency}</p>
                            <span>{'(' + order.items + ')'} {t('Items')}</span>
                          </div>
                          <div>
                            <Link to={'/payment-result?id=' + order.id} className={'btn w-100 my-3 btn-outline-' + (order.paid ? 'success' : 'danger')}>
                              {!order.paid ? t('Payment verification') : t('Receipt')}
                              <i className='fas fa-file-invoice mx-1'></i>
                            </Link>
                            {!order.paid ? <p className='alert alert-success opacity-75'>{t('Re-check that the payment has been completed')}</p> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No orders')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  ) : (
    ''
  );
}

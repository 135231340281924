/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik, useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import axios from 'axios';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';
import phoneFormatImage from '../../images/phone_e164.png';


export default function Register() {
  // const {
  //   values,
  //   handleChange,
  //   setFieldValue,
  //   handleSubmit,
  //   isSubmitting,
  //   isValid // will work with validation schema or validate fn defined
  // } = useFormikContext();


  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser } = USER();
  const { lang } = LANG();
  const { loading, setLoading } = Loader();

  let [searchParams] = useSearchParams({});
  let [rurl, setRurl] = useState(searchParams.get('rurl'));


  let [TimeZones, SetTimeZones] = useState([]);
  let [message, setMessage] = useState();
  let [initVal, setInitVal] = useState({
    fName: '',
    lName: '',
    phoneCode: '+965',
    phoneNumber: '',
    username: '',
    localZone: '',
    termsAgreement: false,
  });

  async function getDeviceDate() {
    let deviceInfo = localStorage.getItem('deviceInfo');
    if (!deviceInfo) {
      await fetch('https://api.ipregistry.co/?key=tryout')
        .then(function (response) {
          return response.json();
        })
        .then(function (payload) {
          deviceInfo = JSON.stringify(payload);
          localStorage.setItem('deviceInfo', deviceInfo)
          console.log("payload", payload);
        });
    }

    var option = document.querySelector('#localZone option[value*="' + JSON.parse(deviceInfo).location.country.name + '"]');
    if (option) {
      let opVal = option.getAttribute('value');
      // option.setAttribute('selected', 'selected');
      // document.getElementById('localZone').setAttribute('value', option.getAttribute('value'));
      // option.click();
      console.log(opVal);
      setInitVal({ ...initVal, localZone: opVal });
      // formik.handleChange();
      return opVal;
    }
  }

  let validationSchema = Yup.object({
    fName: Yup.string()
      .required(t('First Name') + ' ' + t('is required'))
      .min(3, t('First nNme') + ' ' + t('Minimum chars is ') + 3)
      .max(10, t('First Name') + ' ' + t('Maximum chars is ') + 10),
    lName: Yup.string()
      .required(t('Last Name') + ' ' + t('is required'))
      .min(3, t('Last Name') + ' ' + t('Minimum chars is ') + 3)
      .max(10, t('Last Name') + ' ' + t('Maximum chars is ') + 10),
    phoneCode: Yup.string()
      .required(t('Phone Code') + ' ' + t('is required'))
      .matches(/\+\d{1,3}/, t('أدخل رمز الاتصال بالتنسيق الدولي مثل +965')),
    phoneNumber: Yup.string()
      .required(t('Phone Number') + ' ' + t('is required'))
      .matches(/\d{5,14}/, t('أدخل رقم هاتف متاحًا بالتنسيق الدولي مثل 1234567899')),
    username: Yup.string()
      .required(t('Email') + ' ' + t('is required'))
      .email(t('Enter a valid email')),
    localZone: Yup.string().required(t('Select Your local Timezone')),
    passcode: Yup.string()
      .min(6, t('Password') + ' ' + t('Minimum chars is ') + 6)
      .required(t('choose a password')),
    confirmPasscode: Yup.string().required(t('Confirm the password'))
      .oneOf([Yup.ref('passcode'), null], t('Passwords not matches')),
    termsAgreement: Yup.boolean().oneOf([true], t('You must read the terms and conditions and complete the registration by agreeing to them')),
  });

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values, { setSubmitting }) => {
      console.log(formik.errors);
      values.deviceInfo = localStorage.getItem('deviceInfo');
      values.phoneNumber = values.phoneCode + values.phoneNumber;
      console.log('PostRegister', values);
      axios
        .post(APIURL('auth/register'), values)
        .then((resp) => {
          setMessage(resp.data.message);
          console.log('resp.data.message', resp.data.message);
          console.log('resp.data', resp.data);

          if (resp.data.user) {
            localStorage.removeItem('RegisterData');
            setUser(resp.data.user);
            setTimeout(() => {
              navigate(rurl ? rurl : '/profile');
            }, 2500);
          } else {
            setSubmitting(false);
          }
        })
        .catch((error) => {
          console.error('formik.Submitting:Error:', error);
          setSubmitting(false);
          setMessage({ success: false, message: t('Something went wrong') });
        });
    },
    validationSchema,
  });

  async function GetTimeZones() {
    setLoading(true);
    let { data } = await axios.get(APIURL('auth/timeZones')).catch((error) => {
      alert(error);
    });
    SetTimeZones([...data, { name: 'Select', value: '' }]);
    setLoading(false);
  }


  useEffect(() => {
    SetTitle("Sign up");
  });
  useEffect(() => {
    GetTimeZones();
    getDeviceDate();
    if (user != null) navigate(rurl ?? '/profile');
  }, [lang, user]);

  return (
    <>
      <section class="my-5 pt-5" id="convert">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-8 col-sm-12">

              <form class="forms login shadow" onSubmit={formik.handleSubmit}>
                <header class="login__header">
                  <h3 class="login__title">Sign Up</h3>
                </header>
                <div class="login__body">
                  <div className='input-group'>
                    <div class="form-floating mb-3">
                      <input type='text' name='fName' onChange={formik.handleChange} className={'form-control' + (formik.errors.fName ? ' is-invalid' : '')} placeholder='First Name' defaultValue={initVal.fName} id='fName' />
                      <label htmlFor='fName'>{t('First Name')}</label>
                    </div>

                    <div className='form-floating mb-3'>
                      <input type='text' name='lName' onChange={formik.handleChange} defaultValue={initVal.lName} className={'form-control' + (formik.errors.lName ? ' is-invalid' : '')} placeholder='Last Name' id='LName' />
                      <label htmlFor='lName'>{t('Last Name')}</label>
                    </div>
                  </div>

                  <div className='input-group has-validation mb-3'>
                    <div className={'form-floating' + (formik.errors.username ? ' is-invalid' : '')}>
                      <input type='email' name='username' className={'form-control' + (formik.errors.username ? ' is-invalid' : '')} onChange={formik.handleChange} defaultValue={initVal.username} placeholder='Enter Your Email' id='username' />
                      <label htmlFor='Username'>{t('Your Email')}</label>
                    </div>
                    <div className='invalid-feedback'>
                      {formik.errors.username}
                    </div>
                  </div>

                  <div className='input-group has-validation mb-3'>
                    <div className={'input-group ' + (formik.errors.phoneNumber ? ' is-invalid' : '')}>
                      <label class="input-group-text" htmlFor='phoneNumber'>{t('Phone Number')}</label>
                      <input type='text' name='phoneCode' onChange={formik.handleChange} className={'form-control m-0 w-25' + (formik.errors.phoneCode ? ' is-invalid' : '')} placeholder='Phone Code' defaultValue={initVal.phoneCode} id='phoneCode' style={{maxWidth: '100px', textAlign: 'center'}} />
                      <input type='text' name='phoneNumber' onChange={formik.handleChange} className={'form-control' + (formik.errors.phoneNumber ? ' is-invalid' : '')} placeholder='Phone Number' defaultValue={initVal.phoneNumber} id='phoneNumber' />
                    </div>
                    <div className='invalid-feedback'>
                      <ul className='h-auto mx-1 mb-0 p-0 wrapper'>
                        <li className='icon m-0' style={{ boxShadow: 'none', height: 'auto', width: 'auto' }}>
                          <span class="tooltip" style={{ width: '350px', top: '-100px', left: '0' }}><img className='d-block m-auto' width={'50%'} src={phoneFormatImage} alt='Phone Number format example' /></span>
                          <span><i className='fas fa-info-circle'></i> تحقق من التنسيق الصحيح لرقم الهاتف</span>
                        </li>
                      </ul>
                      {formik.errors.phoneCode}
                      {formik.errors.phoneNumber}
                    </div>
                  </div>

                  <div className='input-group has-validation mb-3'>
                    <div className={'form-floating' + (formik.errors.localZone ? ' is-invalid' : '')}>
                      <select className={'form-control' + (formik.errors.localZone ? ' is-invalid' : '')} onChange={formik.handleChange} id='localZone' name='localZone' autoFocus>
                        {TimeZones.map((el) => (
                          <option key={el.value} value={el.value} onChange={(e) => { setInitVal({ ...initVal, localZone: e.target.value }); formik.handleChange(e); }}>
                            {el.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor='localZone'>{t('Your Local TimeZone')}</label>
                    </div>
                    <div className='invalid-feedback'>{formik.errors.localZone}</div>
                  </div>

                  <div className='input-group has-validation mb-3'>
                    <div className={'form-floating' + (formik.errors.passcode ? ' is-invalid' : '')}>
                      <input type='password' name='passcode' onChange={formik.handleChange} className={'form-control' + (formik.errors.passcode ? ' is-invalid' : '')} placeholder='Password' id='passcode' />
                      <label htmlFor='passcode'>{t('Password')}</label>
                    </div>
                    <div className={'form-floating' + (formik.errors.confirmPasscode ? ' is-invalid' : '')}>
                      <input type='password' name='confirmPasscode' onChange={formik.handleChange} className={'form-control' + (formik.errors.confirmPasscode ? ' is-invalid' : '')} placeholder='Confirm Password' id='confirmPasscode' />
                      <label htmlFor='confirmPasscode'>{t('Confirm Password')}</label>
                    </div>
                    <div className='invalid-feedback'>{formik.errors.passcode} {formik.errors.confirmPasscode}</div>
                  </div>

                  <div className=''>
                    <div className='form-check form-check-inline mx-1'>
                      <input className='form-check-input' type='checkbox' id='termsAgreement' name='termsAgreement' value='true' onChange={formik.handleChange} />
                      <label className={'form-check-label ' + (formik.errors.termsAgreement != null ? 'fw-bold text-danger' : '')} style={{ cursor: 'pointer' }} htmlFor='termsAgreement'>
                        {t('I agree to')}
                      </label>
                      <a href='/terms' className='mx-1 pe-auto'>
                        {t('the terms and conditions')}.
                      </a>
                    </div>
                    {formik.errors.termsAgreement != null ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.termsAgreement}</span> : ''}
                  </div>
                  <div className='col-12 overflow-hidden'>
                    {message ? (
                      <div className={'alert w-100 text-wrap alert-' + (message.success ? 'success' : 'danger')}>
                        <p>{message.message}</p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <footer class="login__footer">
                  <button className='btn btn-warning text-white px-5 py-2' disabled={formik.isSubmitting || formik.errors.termsAgreement ? 'disabled' : ''} type='submit'>
                    {formik.isSubmitting ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : t('Sign Up')}
                  </button>
                  <Link to={"/login?rurl=" + rurl} class="ic-inf link text-decoration-none">
                    <span class="icon icon--info">
                      <i class="fas fa-sign-in-alt"></i>
                    </span>
                    <span>Log In</span>
                  </Link>
                </footer>
              </form>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect } from 'react';
import { Loader } from '../../../IWWJsCode';
import Lottie from 'lottie-react';
import fadeBoxsLoading from '../../../lottie-animations/fade-boxes-loading.json';


export default function Footer() {
  let { loading } = Loader();

  return loading ? (
    <>
      <div className='position-fixed bottom-0 top-0 start-0 end-0' style={{ zIndex: 11, cursor: 'wait' }}>
        <div className='position-absolute top-50 start-50 translate-middle'>
          <Lottie animationData={fadeBoxsLoading} />
        </div>
      </div>

    </>
  ) : '';
}

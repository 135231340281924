import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CartButton from '../Materials/Partials/_CartButton';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function Project() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = USER();

  let { lang } = LANG();
  let [project, setProject] = useState();

  useEffect(() => {
    if (!user) {
      navigate('/login?rurl=/project/' + id);
    }

    if (user) {
      // setLoading(true);
      fetch(APIURL(lang + `/userProjects/info/${id}`))
        .then((res) => res.json())
        .then((json) => {
          setProject(json)
          // setLoading(false);
        });
    }
    SetTitle('My Projects');
    window.scrollTo(0, 0);
  }, [id, user, lang]);


  return user && user.Active ? (
    <>
      <section>
        <div class='container'>
          <div class='row justify-content-center'>
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/my-projects'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {project ? (
              <>
                <div className='col-12'>
                  <div class='row mt-2'>
                    <div class='col-md-12'>
                      <h1>{project.name}</h1>
                      <h6>{project.status}</h6>
                      <h6>{project.mode}</h6>
                      <div dangerouslySetInnerHTML={{ __html: project?.summary }}></div>
                    </div>

                    <div class='col-md-12'>
                      <h3>Submissions</h3>
                      <ul className='list-group'>
                        {project.submissions.map((sub, indx) => (
                          <>
                            <li key={indx} className={'list-group-item text-start'}>
                              <span className='float-end'>
                                {sub.paid || sub.price == 0 ? (
                                  <Link className='btn btn-dark' to={'/Submission-Files/' + sub.id + '/' + id}>
                                    <span className='badge bg-text-dark'>
                                      <i class='fas fa-archive mx-2'></i> Files
                                    </span>
                                  </Link>
                                ) : (
                                  <>
                                    <strong className='mr-2'>
                                      {sub.price} {sub.currency}
                                    </strong>
                                    <CartButton price={sub.price} paymentType='Paymnent' type='Project' id={sub.id} />
                                  </>
                                )}
                              </span>
                              <h4 className='me-1 mb-0'>{sub.name}</h4>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No Project Info')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  ) : (
    ''
  );
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import overlay from '../../images/overlay-bottom.png';
import { GET_IMAGE_URL, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function AboutUs() {
  let { setLoading } = Loader();
  let [abutData, setData] = useState();
  let { lang } = LANG();

  useEffect(() => {
    getAboutData(lang);
    SetTitle("About us");
  }, [lang]);

  async function getAboutData(_lang) {
    setLoading(true);
    let { data } = await axios.get(APIURL(_lang + '/web/about'))
      .catch((err) => {
        console.error(err);
      });
    setData(data);
    setLoading(false);
  }

  return abutData && abutData.length ? (
    <>
      {/* <div className='shape-menu'>
        <img src={overlay} className='w-100' />
      </div> */}
      <div className='new-about about-page'>
        <div className='container'>
          <div className='row'>
            {abutData.map((el, i) => (
              <div key={i} className='about-item'>
                <div className='row serv'>
                  <div className={el.image || el.image2 ? 'col-12 col-md-6' : 'col-12 col-md-12'}>
                    <h3 className='head-section'>{el.title}</h3>
                    <div className='about-data' dangerouslySetInnerHTML={{ __html: el.htmlDescription }}></div>
                  </div>
                  {el.image || el.image2 ? (
                    <div className='col-12 col-md-6'>
                      <div className='about-img'>
                        <img src={GET_IMAGE_URL(el.image, el.image2)} alt='' srcSet='' className='w-100' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

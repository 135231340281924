import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { useAnimate, usePresence } from 'framer-motion';

import { APIURL, LANG, GET_IMAGE_URL, Loader, USER, SetTitle } from '../../IWWJsCode';
// import SecLoader from '../Shared/Loaders/SecLoader';
import ChapterBox from './Partials/_ChapterBox';
import SectionBox from './Partials/_SectionBox';
import CartButton from './Partials/_CartButton';
import { useTranslation } from 'react-i18next';

export default function Course() {
  const navigate = useNavigate();
  let { courseId } = useParams();
  let { lang, financialPrice } = LANG();
  let { isUserCourse, user } = USER();
  let { loading, setLoading } = Loader();
  let [course, setCourse] = useState(null);
  let [sections, setSections] = useState([]);
  let [chapters, setChapters] = useState([]);
  let [relatedCourses, setRelatedCourses] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    CheckUser();
  });

  function CheckUser() {
    // if (!user)
    //   navigate('/Course/' + courseId);
  }

  useEffect(() => {
    getCourse(courseId);
    window.scrollTo(0, 0);
    getRelatedCourse(courseId)
  }, [courseId, lang]);

  async function getCourse(id) {
    if (id && id !== '') {
      setLoading(true);
      try {

        fetch(APIURL(lang + `/courses/getById?id=${id}`))
          .then((res) => res.json())
          .then((json) => {
            setCourse(json);
            if (json)
              SetTitle(json.name);
            setSections(json.sections);
            setChapters(json.chapters);
            setLoading(false);
          });
      } catch (error) {
        console.error(APIURL(lang + `/courses/getById?id=${id}`), error)
      }
    }
  }


  async function getRelatedCourse(id) {
    if (id && id !== '') {
      // setLoading(true);
      try {

        fetch(APIURL(lang + `/Courses/related/${id}`))
          .then((res) => res.json())
          .then((json) => {
            setRelatedCourses(json);
            // setLoading(false);
          });
      } catch (error) {
        console.error(APIURL(lang + `/courses/related?id=${id}`), error)
      }
    }
  }

  return (
    <>
      <section id="convert">
        <div class="container">
          <div class="row justify-content-center">
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/courses'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
              {/* {course && course.university ? (
                <Link className='btn btn-outline-light text-dark' to={'/courses/' + course.university.Id}>
                  <i className={t('back-arrow') + ' mx-1'}></i>
                  {t('Back')} {course.university.name}
                </Link>
              ) : (
                <Link className='btn btn-outline-light text-dark' to={'/courses'}>
                  <i className={t('back-arrow') + ' mx-1'}></i>
                  {t('Back')}
                </Link>
              )} */}
            </div>
            <div class="col-md-8 col-sm-12">
              <div class="row">
                {course ? (
                  <>
                    <div class="col-md-12">
                      <div class="card card-info border-0 shadow my-3">
                        <div class="card-header d-lg-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center justify-content-center">
                            {/* <img src={GET_IMAGE_URL(course.image, course.instructor && course.instructor.image ? course.instructor.image : '')} class='inner-course-img img-fluid rounded-end' alt={course.name} /> */}
                            {course.instructor ?
                              <>
                                <img src={GET_IMAGE_URL(course.instructor.image)} class="samll-img" alt={course.instructor.name} />
                                <Link to={'/courses?i=' + course.instructor.id} class="h4 text-decoration-none">
                                  {course.instructor.name}
                                </Link>
                              </> : ('')}
                          </div>
                          <div class="d-flex justify-content-center align-items-center">
                            {/* <strong>{t('Course Name')}:</strong> */}
                            {/* <h1 class="h4 ps-1 m-0">{course.name}</h1> */}
                            <img src={GET_IMAGE_URL(course.university.image)} style={{ height: '58px' }} alt={course.university.name} />
                          </div>
                        </div>
                        <div class="row g-0">
                          <div class="col-md-4">
                            <img src={GET_IMAGE_URL(course.image)} class="img-fluid rounded-start" alt={course.Name} />
                          </div>
                          <div class="col-md-8">
                            <div class="card-body">
                              <h1 class="card-title h4">
                                {isUserCourse(course.id) ? <i className='fas fa-lock-open text-dark mx-1'></i> : !course.isFree ? <><i className='fas fa-lock text-dark mx-1'></i></> : ''}
                                {course.name}
                              </h1>
                              <p class="card-text">{course.university.name} | {course.college.name} | {course.major.name} </p>
                              <p class="card-text"><small class="text-body-secondary">Ends on {course.endDate}</small></p>
                              {!course.isFree && course.price && !isUserCourse(course.id) ? (
                                <>
                                  {course.price > 0 ? <><h5><strong className='mx-1'>{financialPrice(course.price)}</strong> {course.currency}</h5></> : ''}
                                  {/* <i className='fas fa-lock text-dark mx-1'></i> */}

                                  <CartButton paymentType={'Payment'} price={course.price} type='Course' id={course.id} />

                                  {course.paymetPlan ? <>
                                    {/* <CartButton paymentType={'Installment'} price={course.paymetPlan.Base} type='Course' id={course.id} /> */}
                                    <p>{t('Installments available with a down payment')} ({financialPrice(course.paymetPlan.Base)} {course.currency})</p>
                                  </> : ''}
                                </>
                              ) : ('')}
                            </div>
                          </div>
                        </div>

                        {/* <div class="card-body">
                          <div class="Full-Course">
                            <ul class="list-unstyled">
                              <li><strong>University:</strong>{course.university ? <>{course.university.name}</> : ''}</li>
                              <li><strong>Collage:</strong>{course.college ? <>{course.college.name}</> : ''}</li>
                              <li><strong>Degree:</strong>{course.major ? <>{course.major.name}</> : ''}</li>
                              <li><strong>Ends on:</strong>{course.endDate ? <>{course.endDate}</> : ''}</li>
                              {course.durationInHours && course.durationInHours > 0 ? (
                                <p class='card-text'>
                                  <small class='text-muted'>{course.durationInHours} Hours</small>
                                </p>
                              ) : ('')}
                            </ul>
                            <div>
                              {course.price && course.price > 0 ? <><h5><strong className='mx-1'>{financialPrice(course.price)}</strong> {course.currency}</h5></> : ('')}

                              <div>
                                {isUserCourse(course.id) ? (
                                  <i className='fas fa-lock-open text-dark mx-1'></i>
                                ) : course.isFree ? (
                                  <></>
                                ) : !course.isFree && course.price ? (
                                  <>
                                    <i className='fas fa-lock text-dark mx-1'></i>
                                    <CartButton price={course.price} type='Course' id={course.id} />
                                  </>
                                ) : ('')}
                              </div>
                              {course.summary ? <p class='card-text'>{course.summary}</p> : ''}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {loading ? (
                      <div className='alert alert-light'>
                        <h3 className='alert-heading'><i class="fas fa-circle-notch fa-spin mr-1"></i> {t('Loading..')}</h3>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
                {user ?
                  <>
                    {chapters && chapters.length ? (
                      <div class="col-md-12">
                        <div class="card card-info border-0 shadow my-3">
                          {chapters.map((chap, cindex) => (
                            <ChapterBox chapter={chap} key={cindex} />
                          ))}
                        </div>
                      </div>
                    ) : ('')}

                    {sections && sections.length > 0 ?
                      <>
                        <div class="col-md-12">
                          <div class="accordion my-3" id="accordionExample">
                            {sections.map((sec, index) => <SectionBox section={sec} key={index} />)}
                          </div>
                        </div>
                      </>
                      : ''}
                  </>
                  :
                  <>
                    <Link className='btn btn-dark mt-2 w-100 btn-lg' to={'/login?rurl=/Course/' + courseId}>
                      <i class="fas fa-sign-in-alt mr-2"></i> {t('Login to view course content')}
                    </Link>
                  </>}
              </div>
            </div>
            {relatedCourses && relatedCourses.length > 0 ? (
              <>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-Recent">
                        <div class="card-header recent-header">
                          <h5>Related Course</h5>
                        </div>
                        {relatedCourses.map((course, idx) => (
                          <div class="card-body recent-body">
                            <div class="recent-img">
                              <img src={GET_IMAGE_URL(course.image)} alt="" />
                              <div class="date">
                                <p>
                                  <Link to={'/Course/' + course.id} class="text-decoration-none">
                                    {course.name}
                                  </Link>
                                </p>
                              </div>
                            </div>
                            <div class="info-course">
                              <p>
                                <Link to={'/Course/' + course.id} class="text-decoration-none">
                                  {course.instructor.name}
                                </Link>
                              </p>
                              <span>
                                {course.endDate}
                              </span>
                              <span>
                                {course.price} {course.currency}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : ('')}



          </div>
        </div>
      </section>
    </>
  );
}

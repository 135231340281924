import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CartButton from '../Materials/Partials/_CartButton';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';

export default function MyInstallments() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, type } = useParams();
  const { user } = USER();

  let { financialPrice, currency } = LANG();
  let [installments, setInstallments] = useState();

  useEffect(() => {
    if (!user) {
      if (id && type) navigate('/login?rurl=/my-installments/' + type + '/' + id);
      else navigate('/login?rurl=/my-installments');
    }

    if (user) {
      if (id && type)
        setInstallments(user.installments?.installments.filter(i => i.itemId == id && i.type == type))
      else
        setInstallments(user.installments?.installments)
    }
    SetTitle('My installments');
    window.scrollTo(0, 0);
  }, [id, type]);



  return user && user.Active ? (
    <>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div className='col-12'>
              <Link className='btn btn-outline-light text-dark' to={'/my-courses'}>
                <i className={t('back-arrow') + ' mx-1'}></i>
                {t('Back')}
              </Link>
            </div>
            {installments ? (

              <>
                <div className='col-12'>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <h3>Instalments</h3>
                      <ul className="list-group">
                        {installments.map((inst, indx) => (
                          <>
                            <li key={indx} className={"list-group-item text-start"}>
                              <span className='badge mx-1 float-end text-bg-warning'>{inst.type} instalment</span>
                              <i class={"far mx-1 " + (inst.paid ? "fa-check-circle text-success" : "fa-circle")}></i>
                              <h4 className='me-1 mb-0'> {financialPrice(inst.amount)} {currency} • {inst.dueDate}</h4>
                              <p className='me-1 mb-0'>{inst.relativeDate} • {inst.name}</p>
                              {!inst.paid ?
                                <CartButton price={inst.amount} paymentType='Installment' type='CourseInstallment' id={inst.id} />
                                : ''}
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

              </>
            ) : (
              <div className='alert alert-light'>
                <p>{t('No installments')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  ) : (
    ''
  );
}

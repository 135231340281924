import { LANG, CART } from '../../../IWWJsCode';

export default function CartButton({ id, price, type, paymentType }) {
  let { currency, financialPrice } = LANG();
  let { cartItems, addItem } = CART();
  const InCart = cartItems.some((i) => i.id === id && i.type === type);

  return (<>
    <button className={"btn " + (paymentType == 'Payment' ? "btn-warning text-white" : "btn-outline-warning mx-2")} type='button'
      disabled={InCart}
      onClick={(e) => {
        e.stopPropagation();
        addItem({ id: id, type: type, paymentType });
      }}>
      <i class="fas fa-cart-plus mx-1"></i>
      {InCart ? <>
        <span className='mx-1'>Already in the cart</span>
      </> : <>
        <span className='mx-1'>Add {(type == 'CourseInstallment' ? 'Installment' : type)}</span>
      </>}
      {(paymentType == 'Installment' ? <><i class="fas fa-calendar-alt mx-1"></i> {financialPrice(price)} {currency}</> : '')}
    </button>

  </>
  )
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIURL } from '../IWWJsCode';


const LanguageContext = createContext();

export function useLang() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const { i18n } = useTranslation();

  let [lang, setLang] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
  let [currency, setCurrency] = useState('rCUR');

  useEffect(() => {
    i18n.changeLanguage(lang);
    getCurrency();
    updatePageDirection();
  }, [lang]);

  function updatePageDirection() {
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
    document.documentElement.lang = lang;

    if (lang === 'ar') {
      document.head.querySelector('link[data-bs-link]').setAttribute('href', '/static/css/bootstrap.rtl.min.css');
    } else {
      document.head.querySelector('link[data-bs-link]').setAttribute('href', '/static/css/bootstrap.min.css');
    }

    localStorage.setItem('lang', lang);
  }

  async function getCurrency() {
    fetch(APIURL(lang + '/Settings/Currency'))
      .then((res) => res.json())
      .then((json) => {
        setCurrency(json);
      });
  }

  function financialPrice(price) {
    return Number.parseFloat(price).toFixed(0);
  }
  function Dateformat(date) {
    return new Date(date).toLocaleDateString(lang, { month: 'long', year: 'numeric', day: 'numeric' });
  }

  return <LanguageContext.Provider value={{ lang, setLang, currency, financialPrice, Dateformat }}>{children}</LanguageContext.Provider>;
}

import { useEffect } from 'react';
import { stagger, useAnimate, usePresence } from 'framer-motion';

import { GET_IMAGE_URL } from '../../../IWWJsCode';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function UniversityBox({ university }) {
  const [isPresent, safeToRemove] = usePresence();
  const { t } = useTranslation();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimate = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 2, delay: stagger(1.5) });
      };
      enterAnimate()
    } else {
      const exitAnimate = async () => {
        await animate(scope.current, { opacity: [1, 0] }, { duration: 2, delay: stagger(1.5) });
        safeToRemove()
      };
      exitAnimate()
    }
  }, [university]);


  return (
    <>
      <div ref={scope} key={university.id} class="card card-course border-0 shadow">
        <div class="card-cover">
          <img src={GET_IMAGE_URL(university.image)} class="course-image" alt={university.name} />
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between py-3">
            <div>
              <i class="fas fa-book mx-1"></i>
              <span>{university.coursesCount} {t('Courses')}</span>
            </div>
          </div>
          <div class="course-name py-3">
            <h5>{university.name}</h5>
          </div>
          <div class="card-footer text-center border-top">
            <Link to={'/courses?u=' + university.id} class="text-decoration-none btn btn-warning text-white">
              {t('Explore Courses')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

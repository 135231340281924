import React, { useEffect, useState } from 'react';
// import overlay from '../../images/overlay-bottom.png';
// import logo from '../../images/logo.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { USER, APIURL, LANG, Loader, SetTitle } from '../../IWWJsCode';
import { useTranslation } from 'react-i18next';

export default function Login() {
  let navigate = useNavigate();
  let { setLoading } = Loader();
  let [loginLoading, setLoginLoading] = useState();
  let [errorMessage, setErrorMessage] = useState();
  const { user, setUser, loadingUser } = USER();
  const { lang } = LANG();
  const { t } = useTranslation();

  let [searchParams] = useSearchParams({});
  let [rurl] = useState(searchParams.get('rurl'));


  function CheckUser() {
    // getUser();

    if (user != null)
      navigate(rurl ? rurl : '/profile');
  }

  let initVal = {
    username: '',
    passcode: '',
    rememberMe: false,
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values, { setSubmitting }) => {
      setLoginLoading(true);
      axios
        .post(APIURL('auth/login'), values)
        .then((resp) => {
          setErrorMessage(resp.data);
          if (resp.data.success) {
            if (resp.data.user) 
              setUser(resp.data.user);
          }
          setLoginLoading(false);
          setSubmitting(false);
        })
        .catch((error) => {
          console.error('Error', error);
          if (error.status !== 200) {
            setErrorMessage(error.response.data);
          }
          setLoginLoading(false);
          setSubmitting(false);
        });
    },
    validationSchema: Yup.object({
      username: Yup.string().required().email('Enter an available email'),
      passcode: Yup.string().min(4).required('Password is required'),
      rememberMe: Yup.boolean(),
    }),
  });

  useEffect(() => {
    SetTitle("Login");
  });
  useEffect(() => {
    setLoginLoading(true);
    CheckUser();
    setLoginLoading(false);
  }, [user, lang]);

  useEffect(() => {
    setLoading(loadingUser);
  }, [loadingUser]);

  return !loadingUser ? (
    <>
      <section class="my-5 pt-5" id="convert">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-8 col-sm-12">

              <form onSubmit={formik.handleSubmit} class="forms login shadow">
                <header class="login__header">
                  <h3 class="login__title">Login</h3>
                </header>
                <div class="login__body">
                  <div class="form-floating mb-3">
                    <input type='email' name='username' className={'form-control' + (formik.errors.username ? ' is-invalid' : '')} onChange={formik.handleChange} placeholder={t('Enter Your Email')} id='username' />
                    <label htmlFor='username'>{t('Email')}</label>
                  </div>
                  <div class="form-floating">
                    <input type='password' name='passcode' className={'form-control' + (formik.errors.passcode ? ' is-invalid' : '')} onChange={formik.handleChange} placeholder={t('Enter Your Password')} id='passcode' />
                    <label htmlFor='passcode'>{t('Password')}</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="rememberMe" name='rememberMe' onChange={formik.handleChange} />
                    <label class="form-check-label" for="rememberMe">
                      {t('Remember Me')}
                    </label>
                  </div>
                  <div>
                    {errorMessage ? <span className={'badge text-wrap text-bg-' + (errorMessage.success ? 'success' : 'danger') + ' w-100 my-2'} dangerouslySetInnerHTML={{ __html: errorMessage.message }}></span> : ''}
                  </div>
                </div>
                <footer class="login__footer">
                  <button type='submit' className='btn btn-warning text-white px-5 py-2' disabled={formik.isSubmitting || loginLoading || formik.errors.passcode || formik.errors.username ? 'disabled' : ''}>
                    {loginLoading ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : t('Log In')}
                  </button>
                  <Link to={'/register?rurl=' + rurl} className='ic-inf link text-decoration-none'>
                    <span class="icon icon--info">
                      <i class="fas fa-sign-in-alt"></i>
                    </span>
                    {t('Sign Up')}
                  </Link>
                </footer>
              </form>
              <a href='/Account/ForgotPassword' className='link float-right text-decoration-none'>
                <p>{t('Forgot your password ?')}</p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : '';
}

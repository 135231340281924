/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */

import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { APIURL, LANG, USER, GET_IMAGE_URL, Loader, SetTitle } from '../../IWWJsCode';
import CartButton from './Partials/_CartButton';
import { useTranslation } from 'react-i18next';

import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function Chapter() {
  let { lang, financialPrice, currency } = LANG();
  let { loading, setLoading, } = Loader();
  let { chapterId } = useParams();
  let { user, isUserChapter } = USER();
  let [error, setError] = useState();
  let [chapter, setChapter] = useState();
  let [dataLoading, setDataLoading] = useState(false);
  let [videoId, setVideoId] = useState();
  let [video, setVideo] = useState();
  let [bookId, setBookId] = useState();
  let [book, setBook] = useState();
  let [nextChapter, setNextChapter] = useState();
  let [prevChapter, setPrevChapter] = useState();

  const { t } = useTranslation();

  let [authorized, setAuthorized] = useState();
  // {
  //   auth: false,
  //   error: null,
  // }
  const defaultLayoutPluginInstance = defaultLayoutPlugin();


  useEffect(() => {
    getChapter(chapterId);
    window.scrollTo(0, 0);
  }, [chapterId, lang]);

  async function getChapter(id) {
    setError(null);

    if (id && id !== '') {
      setLoading(true);
      fetch(APIURL(lang + `/chapters/chapter?id=${id}`))
        .then((res) => res.json())
        .then((json) => {

          if (json && json.authorized) setAuthorized(json.authorized);
          if (json && json.chapter) setChapter(json.chapter);
          if (json.chapter)
            SetTitle(json.chapter.name + ' | ' + json.chapter.courseName);

          if (json.error)
            setError(json.error);

          setNextChapter();
          if (json && json.nextChapter)
            setNextChapter(json.nextChapter);

          setPrevChapter();
          if (json && json.prevChapter)
            setPrevChapter(json.prevChapter);

          setLoading(false);
        });
    } else {
      setChapter(null);
    }
  }

  useEffect(() => {
    // if (book || bookId) {
    //   setBook(null);
    //   setBookId(null);
    // }

    if (authorized && authorized.auth && videoId && videoId !== '') {
      playVideo(videoId);
    }
  }, [videoId, lang]);

  async function playVideo(id) {
    setError(null);
    if (user && user.Active && id && id !== '') {
      setDataLoading(true);
      fetch(APIURL(lang + `/chapters/video?id=${id}&chapterId=${chapterId}`))
        .then((res) => res.json())
        .then((json) => {
          if (json.authorized) {
            setAuthorized(json.authorized);
          }

          if (json.video) {
            setVideo(json.video);
            if (video)
              SetTitle(video.name);
          }

          if (json.error) {
            setError(json.error);
            closeVideo();
          }

          setDataLoading(false);
        });
    }
  }

  useEffect(() => {
    // if (video || videoId) {
    //   setVideo(null);
    //   setVideoId(null);
    // }

    if (authorized && authorized.auth && bookId && bookId !== '') {
      renderBookPage(bookId);
    }
  }, [bookId, lang]);

  function closeBook() {
    if (book || bookId) {
      setBook(null);
      setBookId(null);
    }
  }

  function closeVideo() {
    if (video || videoId) {
      setVideo(null);
      setVideoId(null);
    }
  }
  async function renderBookPage(id, index) {
    setError(null);
    setDataLoading(true);

    if (user && user.Active && id && id !== '') {
      fetch(APIURL(lang + `/chapters/book?id=${id}&chapterId=${chapterId}`))
        .then((res) => res.json())
        .then((json) => {
          if (json && json.authorized) {
            setAuthorized(json.authorized);
          }

          if (json.book) {
            setBook(json.book);
            if (book)
              SetTitle(book.name);
          }

          if (json.error) {
            setError(json.error);
            closeBook();
          }

          setDataLoading(false);
        });
    } else {
      setDataLoading(false);
    }
  }


  return <>
    <div class="container">
      <div class="row">
        {chapter ? <>

          <div class="col-md-12">
            <Link className='btn btn-outline-light text-dark' to={'/Course/' + chapter.courseId}>
              <i className={t('back-arrow') + ' mx-1'}></i>
              {chapter.courseName}
            </Link>
          </div>
          <div className='col-12'>
            <div class='card mb-3'>
              <div class='card-body'>
                <h1 class="display-5 text-center">{chapter.name} • {chapter.instructor.name}</h1>
                {nextChapter ?
                  <Link className='nav-link float-end' to={'/Chapter/' + nextChapter.id}>
                    {nextChapter.name}
                    <i className={t('next-arrow') + ' mx-1'}></i>
                  </Link>
                  : ''}

                {prevChapter ?
                  <Link className='nav-link' to={'/Chapter/' + prevChapter.id}>
                    <i className={t('back-arrow') + ' mx-1'}></i>
                    {prevChapter.name}
                  </Link>
                  : ''}
              </div>
            </div>
          </div>
        </> : ''}

        {loading || !chapter ? (
          <div className='col-sm-12'>
            <div className='alert alert-light'>
              <h3 className='alert-heading'><i class="fas fa-circle-notch fa-spin mr-1"></i> {t('Loading..')}</h3>
            </div>
          </div>
        ) : ''}

        {authorized && authorized?.auth && error && error !== '' ?
          <div className='col-12'>
            <div className='alert alert-danger'>
              <h3>Error</h3>
              <hr />
              <p>{error}</p>
            </div>
          </div> : ''}
      </div>
    </div>

    {chapter && authorized?.auth ?
      <>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-12'>
              <div class="row justify-content-center my-3">
                {chapter.videos.map((vdo, cindex) => (
                  <>
                    <div key={cindex} class="col-lg-3 col-md-4" >
                      <div className={"card cursor-pointer card-video shadow" + (videoId === vdo.id ? ' border border-warning' : '')}
                        onClick={() => {
                          if (videoId === vdo.id) closeVideo();
                          else setVideoId(vdo.id);
                        }} >
                        {/* <div class="layer-video">
                          <img src={GET_IMAGE_URL(vdo.image)} alt=" as" />
                        </div> */}
                        <div class="card-body video-text bg-light">
                          <h6><i className={'mx-1 ' + (videoId === vdo.id ? 'fas fa-compact-disc fa-spin' : 'fas fa-play-circle')}></i>{vdo.name}</h6>
                          <span className='badge text-bg-dark m-1'>
                            <i class="fas fa-clock mx-1"></i>{vdo.time}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                {chapter.books.length
                  ? chapter.books.map((ibook, cindex) => (
                    <>
                      <div key={cindex} class="col-lg-3 col-md-4">
                        <div class={"card card-Bdf cursor-pointer shadow" + (bookId === ibook.id ? ' border border-warning' : '')}
                          onClick={() => {
                            if (bookId === ibook.id) closeBook();
                            else setBookId(ibook.id);
                          }}>
                          {/* <div class="layer-Bdf">
                            <i class={bookId === ibook.id ? 'fas fa-book-open' : 'fas fa-book'}></i>
                          </div> */}
                          <div class="card-body video-text bg-light">
                            <h6><i className={'mx-1 ' + (bookId === ibook.id ? 'fas fa-book-open' : 'fas fa-book')}></i>{ibook.name}</h6>
                            {ibook.pagesCount && ibook.pagesCount > 0 ? (
                              <span className='badge text-bg-dark m-1'>
                                <i className='fas fa-copy mx-1'></i>
                                {ibook.pagesCount} page
                              </span>
                            ) : <span className='badge text-bg-dark m-1'>
                              <i className='fas fa-file-pdf mx-1'></i>
                              PDF
                            </span>}
                          </div>
                        </div>
                      </div>
                    </>
                  )) : ''}
              </div>
              {dataLoading ? (
                <div class='d-flex justify-content-center'>
                  <div class='spinner-border text-warning' style={{ width: '3rem', height: '3rem' }} role='status'>
                    <span class='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-md-11 col-12'>
              <div className='row justify-content-center'>
                <div className={book ? 'col-12 col-lg-7' : 'col-md-10'}>
                  {video != null ?
                    <div className='row sticky-top' style={{ top: '81px', zIndex: 9 }}>
                      <div className='col-md-12'>
                        <span className='btn btn-sm btn-light w-100' onClick={() => { closeVideo(); }}><i className='fas fa-times'></i></span>
                        {video.url ? (
                          <div className='ratio ratio-16x9'>
                            <iframe src={video.url} style={{ border: '0' }} allow='encrypted-media' allowfullscreen={'allowfullscreen'}></iframe>
                          </div>
                        ) : (
                          <div className='alert alert-warning'>
                            <h4 className='alert-heading'>{video.name} in progress.</h4>
                            <hr />
                            <p>
                              It may take some time, if it takes more than 30 minutes, please <Link to={'/contactus'}>inform the administrators</Link>.
                            </p>
                          </div>
                        )}
                        <h3>{video.name}</h3>
                      </div>
                    </div>
                    : ''}
                </div>
                {book && ((book.pages && book.pages.length) || book.pdf) ? (
                  <div className={video ? 'col-12 col-lg-5' : 'col-md-10'}>
                    <div className='row'>
                      <div className='btn-group'>
                        <a href={book.pdf} download={book.pdfName} className='btn btn-success w-75'>
                          <i className='fas fa-cloud-download-alt mx-1'></i>
                          {book.pdfName}
                        </a>
                        <span className='btn btn-sm btn-light w-25' onClick={() => { closeBook(); }}><i className='fas fa-times'></i></span>
                      </div>
                      {book.pdf ? (
                        <>
                          <div className='col-12' style={{ border: '0', height: '780px' }}>
                            {/* {book.pdf && book.pdf !== '' ? (
                          <>
                            <a href={book.pdf} download={book.pdfName} className='badge text-wrap text-bg-success my-1'>
                              <i className='fas fa-cloud-download-alt mx-1'></i>
                              {book.pdfName}
                            </a>
                          </>
                        ) : ''} */}
                            <div style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '750px',
                            }}>
                              <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'}>
                                <Viewer
                                  fileUrl={book.pdf}
                                  // pageLayout={defaultLayoutPluginInstance}
                                  plugins={[
                                    defaultLayoutPluginInstance,
                                    // thumbnailPluginInstance,
                                  ]}
                                  initialPage={0}
                                  httpHeaders={{ mode: "no-cors" }}
                                // defaultScale={SpecialZoomLevel.PageWidth}
                                />
                              </Worker>
                            </div>
                            {/* <embed src={`https://docs.google.com/viewerng/viewer?url=https://smartvision-academy.com${book.pdf}&embedded=true`} style={{ border: '0', minHeight: '100%', width: '100%' }} allow='encrypted-media' allowfullscreen={'allowfullscreen'}></embed> */}
                          </div>
                        </>
                      ) : (
                        <>
                          {book.pages.map((page) => (
                            <div className='col-md-12 mb-2 img-thumbnail overflow-hidden' style={{ position: 'relative' }}>
                              <h3>{'(' + page.index + ')'} {page.title}</h3>
                              <span style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', zIndex: '1071', cursor: 'default', }}></span>
                              <img src={page.image} className='w-100' style={{ height: 'auto' }} alt='book page' />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        </div>
      </>
      : <div className='row justify-content-center'>
        <div className='col-md-6'>
          {!loading && authorized && !authorized.auth ?
            <div class='alert alert-light text-center' role='alert'>
              <h4 class='alert-heading'><i class="fas fa-ban mx-1"></i>Access Denied</h4>
              {authorized.error ? <p class='mb-0'>{authorized.error}</p> : ''}

              {user && chapter && chapter.installment ? <>
                <h5 class='alert-heading'><i class="fas fa-calendar-check mx-1"></i>These installments are required to reactivate this course</h5>
                <ul className='list-group'>
                  {chapter.installment.map((inst, index) => (<>
                    {inst.required ?
                      <>
                        <li className='list-group-item text-start'>
                          <h4 className='me-1 mb-0'> {financialPrice(inst.amount)} {currency} • {inst.dueDate}</h4>
                          <p className='me-1 mb-0'>{inst.relativeDate} • {inst.name}</p>
                          <CartButton price={inst.amount} paymentType='Installment' type='CourseInstallment' id={inst.id} />
                        </li>
                      </>
                      : ''}
                  </>))}
                </ul>
              </> : <>
                {user && (!authorized.auth) && chapter && chapter.price && chapter.price > 0 ?
                  <>
                    <strong className='mx-1'>{financialPrice(chapter.price)} {chapter.currency}</strong>
                    <i className='fas fa-lock text-dark mx-1'></i>
                    <CartButton price={chapter.price} type='Chapter' id={chapter.id} />
                    <hr />
                  </>
                  : ''}
              </>}


              {!user ?
                <>
                  <hr />
                  <Link className='btn btn-dark' to={'/login?rurl=/Chapter/' + chapter?.id}><i class="fas fa-sign-in-alt me-2"></i>Login</Link>
                  <p> or </p>
                  <Link className='btn btn-dark' to={'/Register?rurl=/Chapter/' + chapter?.id}><i class="fas fa-user-plus me-2"></i> Signup</Link>
                </> : ''}
            </div>
            : ''}


        </div>
      </div>}
  </>
}

import settings from './settings.json';
import { useUser } from './Contexts/UserContext';
import { useCart } from './Contexts/CartContext';
import { useLang } from './Contexts/LanguageContext';
import { useLoader } from './Contexts/LoaderContext';
import defaultImage from './images/virtical-logo.png';

export const LANGUAGES = [
  { label: 'English', code: 'en' },
  { label: 'عربي', code: 'ar' },
];

export function LANG() {
  return useLang();
}

export function Loader() {
  return useLoader();
}

export function HostURL(file) {
  return settings.Host + file;
}

export function APIURL(page) {
  return settings.APiUrl + page;
}

export function SetTitle(pageTitle) {
  document.title = pageTitle + ' - ' + settings.Title;
}

export function USER() {
  return useUser();
}

export function CART() {
  return useCart();
}

export function GET_IMAGE_URL(image, other) {
  if (!image || image === '') return other || other !== '' ? other : defaultImage;
  return image;
}

import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    supportedLngs: ['en', 'ar'],
    lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // http://localhost:49727/
      // https://smartvision-academy.com
      loadPath: `${window.location.protocol}//${window.location.host}/static/i18n/{{lng}}.json`,
    },
  });

export default i18n;

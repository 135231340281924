/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Loader } from '../../../IWWJsCode';
import { useTranslation } from 'react-i18next';

export default function SecLoader({ type }) {
  let { loading } = Loader();
  const { t } = useTranslation();
  
  function cartLoader() {
    return (
      <>
        <ul className='list-group mb-2' style={{ display: loading ? 'block' : 'none' }}>
          <li className='list-group-item d-flex justify-content-between placeholder-wave align-items-start w-100' aria-hidden={true}>
            <span className='placeholder rounded col-4'></span>
            <span className='col-4'></span>
            <span className='placeholder placeholder-lg rounded col-1'></span>
            <a href='#' tabIndex={-1} className='btn btn-secondary disabled placeholder col-1'></a>
          </li>
          <li className='list-group-item d-flex justify-content-between placeholder-wave align-items-start w-100' aria-hidden={true}>
            <span className='placeholder rounded col-4'></span>
            <span className='col-4'></span>
            <span className='placeholder placeholder-lg rounded col-1'></span>
            <a href='#' tabIndex={-1} className='btn btn-secondary disabled placeholder col-1'></a>
          </li>
          <li className='list-group-item d-flex justify-content-between placeholder-wave align-items-start w-100' aria-hidden={true}>
            <span className='placeholder rounded col-4'></span>
            <span className='col-4'></span>
            <span className='placeholder placeholder-lg rounded col-1'></span>
            <a href='#' tabIndex={-1} className='btn btn-secondary disabled placeholder col-1'></a>
          </li>
        </ul>
      </>
    );
  }

  function chapterLoader() {
    return (
      <>
        <ul className='list-group' style={{ display: loading ? 'block' : 'none' }}>
          <li className='list-group-item placeholder-wave d-flex justify-content-between align-items-start'>
            <div className='col-4 placeholder placeholder-lg rounded'></div>
            <div className='btn-group col-4' role='group'>
              <a href='#' tabIndex={-1} className='btn btn-dark disabled placeholder'></a>
              <a href='#' tabIndex={-1} className='btn btn-outline-warning disabled placeholder'></a>
            </div>
          </li>
          <li className='list-group-item placeholder-wave d-flex justify-content-between align-items-start'>
            <div className='col-4 placeholder placeholder-lg rounded'></div>
            <div className='btn-group col-4' role='group'>
              <a href='#' tabIndex={-1} className='btn btn-dark disabled placeholder'></a>
              <a href='#' tabIndex={-1} className='btn btn-outline-warning disabled placeholder'></a>
            </div>
          </li>
          <li className='list-group-item placeholder-wave d-flex justify-content-between align-items-start'>
            <div className='col-4 placeholder placeholder-lg rounded'></div>
            <div className='btn-group col-4' role='group'>
              <a href='#' tabIndex={-1} className='btn btn-dark disabled placeholder'></a>
              <a href='#' tabIndex={-1} className='btn btn-outline-warning disabled placeholder'></a>
            </div>
          </li>
          <li className='list-group-item placeholder-wave d-flex justify-content-between align-items-start'>
            <div className='col-4 placeholder placeholder-lg rounded'></div>
            <div className='btn-group col-4' role='group'>
              <a href='#' tabIndex={-1} className='btn btn-dark disabled placeholder'></a>
              <a href='#' tabIndex={-1} className='btn btn-outline-warning disabled placeholder'></a>
            </div>
          </li>
        </ul>
      </>
    );
  }

  function receiptLoader() {
    return (
      <>
        <div style={{ display: loading ? 'block' : 'none' }} className='card-body mx-4'>
          <div className='container'>
            <p className='my-5 mx-5' style={{ fontSize: '30px' }}>
              <span className='placeholder rounded col-8'></span>
            </p>
            <div className='row'>
              <ul className='list-unstyled placeholder-wave'>
                <li className='text-black'>
                  <span className='placeholder rounded col-7'></span>
                </li>
                <li className='text-muted mt-1'>
                  <span className='text-black float-end'>
                    <span className='placeholder rounded col-4'></span>
                  </span>
                  <span className='text-black'>
                    {t('Invoice')} #<span className='placeholder rounded col-4'></span>
                  </span>
                  <span className='placeholder rounded col-4'></span>
                  <hr />
                </li>
              </ul>
              <hr />
            </div>
            <div className='row'>
              <div className='col-xl-10'>
                <p>
                  <span className='placeholder rounded col-7'></span>
                </p>
              </div>
              <div className='col-xl-2'>
                <p className='float-end'>
                  <span className='placeholder rounded col-4'></span>
                </p>
              </div>
              <hr />
            </div>
            <div className='row'>
              <div className='col-xl-10'>
                <p>
                  <span className='placeholder rounded col-7'></span>
                </p>
              </div>
              <div className='col-xl-2'>
                <p className='float-end'>
                  <span className='placeholder rounded col-4'></span>
                </p>
              </div>
              <hr />
            </div>

            <div className='row text-black'>
              <hr style={{ border: '2px solid black' }} />
              <div className='col-xl-12'>
                <p className='float-end fw-bold'>
                  {t('Total')}:<span className='placeholder placeholder-lg rounded col-6'></span>
                </p>
              </div>
              <hr style={{ border: '2px solid black' }} />
            </div>
          </div>
        </div>
      </>
    );
  }

  function coursesLoader() {
    return (
      <>
        <div className='card' aria-hidden={true} style={{ display: loading ? 'block' : 'none' }}>
          <svg
            className='bd-placeholder-img card-img-top'
            width='100%'
            height='180'
            xmlns='http://www.w3.org/2000/svg'
            // role="img"
            aria-label='Placeholder'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'>
            <title>Placeholder</title>
            <rect width='100%' height='100%' fill='#868e96'></rect>
          </svg>
          <div className='card-body placeholder-wave w-100'>
            <h5 className='card-title'>
              <span className='placeholder rounded col-6'></span>
            </h5>
            <p className='card-text'>
              <span className='placeholder rounded col-7'></span>
              <span className='placeholder rounded col-4'></span>
              <span className='placeholder rounded col-4'></span>
              <span className='placeholder rounded col-6'></span>
              <span className='placeholder rounded col-8'></span>
            </p>
            <a tabIndex={-1} className='btn btn-dark disabled placeholder col-6'></a>
          </div>
        </div>
      </>
    );
  }

  function defaultLoader() {
    return (
      <>
        <div className='card' aria-hidden={true} style={{ display: loading ? 'block' : 'none' }}>
          <svg
            className='bd-placeholder-img card-img-top'
            width='100%'
            height='180'
            xmlns='http://www.w3.org/2000/svg'
            // role="img"
            aria-label='Placeholder'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'>
            <title>Placeholder</title>
            <rect width='100%' height='100%' fill='#868e96'></rect>
          </svg>
          <div className='card-body placeholder-wave w-100'>
            <h5 className='card-title'>
              <span className='placeholder rounded col-6'></span>
            </h5>
            <p className='card-text'>
              <span className='placeholder rounded col-7'></span>
              <span className='placeholder rounded col-4'></span>
              <span className='placeholder rounded col-4'></span>
              <span className='placeholder rounded col-6'></span>
              <span className='placeholder rounded col-8'></span>
            </p>
            <a tabIndex={-1} className='btn btn-dark disabled placeholder col-6'></a>
          </div>
        </div>
      </>
    );
  }

  function getLoader() {
    switch (type) {
      case 'cart':
        return cartLoader();
      case 'chapter':
        return chapterLoader();
      case 'courses':
        return coursesLoader();
      case 'receipt':
        return receiptLoader();
      default:
        return defaultLoader();
    }
  }

  return getLoader();
}